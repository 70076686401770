import { EInsufficientFunds } from "@/types";

const useGameModal = () => {
	const { data: appInit } = useAppInitData();
	const loginGuard = useLoginGuard();
	const { open, close } = useAppModals();
	const { isSweepStakes } = useSwitchMode();
	const { winnings } = useBalance();
	const isGuest = useIsGuest();
	const { sendAdTrigger } = usePopupAds();

	const route = useRoute();
	const hasPromoOffer = computed(() => !!appInit.value?.bannerPresetPackage);

	const insufficientFundsModalType = computed(() => {
		if (!isSweepStakes.value) {
			if (hasPromoOffer.value) {
				return EInsufficientFunds.TOURNAMENT_WITH_OFFER;
			}

			if (winnings.value > 1) {
				return EInsufficientFunds.TOURNAMENT_WITH_WINNINGS;
			}

			return EInsufficientFunds.NO_OFFER;
		}

		if (winnings.value) {
			return EInsufficientFunds.SWEEPSTAKES_WITH_WINNINGS;
		}

		return hasPromoOffer.value ? EInsufficientFunds.SWEEPSTAKES_WITH_OFFER : EInsufficientFunds.NO_OFFER;
	});

	const { data: game, execute: getGame } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: { id: (route.query.game as string) || "", mode: !isGuest.value && route.query.isMoney ? "/play" : "/" }
		},
		options: {
			transform: (data) => data?.[0],
			immediate: false
		}
	});

	const openCashModal = () => {
		window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
	};

	const openInsufficientFundsModal = () => {
		const transactions = window?.$cash?.$store?.getters?.["history/transactions"] as Array<Record<string, string>>;
		const transactionOut = transactions?.find((t) => t?.type === "out" && t?.status === "pending");

		loginGuard({
			success: () => {
				if (transactionOut && winnings.value < 1 && isSweepStakes.value) {
					open("LazyOModalInsufficientWithdrawal", {
						amount: numberFormat(+transactionOut.amount),
						id: +transactionOut.id
					});
				} else {
					open("LazyOModalInsufficientFunds", { modalType: insufficientFundsModalType.value });
				}

				dispatchGAEvent({
					type: isSweepStakes.value ? "winnings" : "coins",
					event: "open_popup",
					formName: "empty_balance",
					location: "gameplay"
				});
			},
			fail: () => {
				open("LazyOModalDemoMode");
			}
		});
	};

	const closeModal = async () => {
		if (route?.query?.game) {
			sendAdTrigger("closeGame");
		}

		await navigateTo(route.path, { replace: true });
		close("LazyOModalGame");
	};

	const registerGameMessages = (event: MessageEvent) => {
		if (event.data.cash?.popups?.show) {
			openInsufficientFundsModal();
		}

		if (event.data.cash?.popups?.create) {
			const eventSource = event.source as typeof window.parent;

			if (eventSource) {
				eventSource.postMessage({ cash: { popups: { created: true } } }, "*");
			}
		}

		if (event.data.cash?.open) {
			openCashModal();
		}

		if (event.data.game?.close) {
			closeModal();
		}
	};

	watch(
		() => route.query.game,
		(value, oldValue) => {
			if (oldValue && value === undefined) {
				closeModal();
			}
		}
	);

	const isUserInGame = computed(() => route.query?.game);

	return {
		game,
		isUserInGame,
		openCashModal,
		getGame,
		registerGameMessages,
		closeModal
	};
};
export default useGameModal;
